import React from 'react';

import './style.scss';
import Helmet from './helmet';
import Header from './header';
import { createGlobalStyle } from "styled-components";
import * as fonts from "../fonts";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faEnvelope  } from '@fortawesome/free-solid-svg-icons';

library.add(faCheck, faEnvelope);

const GlobalStyle = createGlobalStyle`
  @import url('https://indestructibletype.com/fonts/Bodoni/Bodoni.css');

  @font-face {
    font-family: 'SunKissed regular';
    font-style: normal;
		font-weight: normal;
		font-display: auto;
	src: local('SunKissed regular'), 
		url('${fonts.SunKissedWOFF}') format('woff'),
		url('${fonts.SunKissedWOFF2}') format('woff2'),
    url('${fonts.SunKissedTTF}') format('truetype');
  }

	body {
		font-family: 'Bodini* 16';
		color: #314386;
	}

  .text-name {
		font-family: 'SunKissed regular';
  }

`;

const Layout = ({ children }) => (
	<div>
		<GlobalStyle />
		<Helmet />
		<Header />
	</div>
);

export default Layout;
