import React from 'react';

import './style.scss';

import Navbar from '../components/navbar.js';
import Footer from '../components/footer.js';

const Header = ({ siteTitle }) => (
	<section className="hero has-bg-img is-fullheight-with-navbar is-fullheight-with-footer">
		<Navbar/>
		<div className="hero-body">
		</div>
		<Footer/>
	</section>
);

export default Header;
