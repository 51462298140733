import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => (
	<StaticQuery
		query={graphql`
			query SocialQuery {
				site {
					siteMetadata {
						gatsby
						bulma
						twitter
						medium
						github
					}
				}
			}
		`}

		render={data => (
			<footer className="hero-foot center">
				<div className="content has-text-centered">
					<p className="is-size-4 is-marginless">
						Here you'll soon find great yoga classes
					</p>
					<p className="is-size-4 is-marginless">
						health blog posts and other stuff that
					</p>
					<p className="is-size-4 is-marginless">
						I keep close to my heart!
					</p>
					<form name="email" method="POST" action="#" data-netlify="true" data-netlify-honeypot="bot-field">
						<input type="hidden" name="bot-field" />
  						<input type="hidden" name="form-name" value="email" />
						<p className="is-size-4 is-marginless">
							Register your Email:
						</p>
						<div className="field has-addons has-padding-top-7 has-padding-bottom-7">
  							<div className="control has-icons-left has-icons-right is-expanded">
    							<input className="input is-primary" type="email" name="email" placeholder="Your email" />
								<span className="icon is-small is-left">
									<i><FontAwesomeIcon icon="envelope" /></i>
								</span>
							</div>
  							<div className="control">
    							<button className="button is-primary" type="submit">
      								Send
    							</button>
  							</div>
						</div>
					</form>
					<p className="is-size-4 is-marginless">
						and I'll let you know when we're ready!
					</p>
					<p className="is-size-4 is-marginless">
						Love
					</p>
					<p className="text-name is-size-4 is-marginless">
						Emilie
					</p>	
				</div>
			</footer>
		)}
	/>
);

export default Footer;
