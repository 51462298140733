import React from 'react';

import './style.scss';
import strongyogiLogo from '../images/strongyogi_logo.svg';

const Navbar = () => (
	<div className="hero-head">
		<nav className="navbar">
			<div className="container center">
				<div className="has-text-centered">
					<p className="has-margin-top-2 is-size-3">Welcome to</p>
  					<img src={strongyogiLogo} width="400" alt="strongyogi logo"></img>
				</div>
			</div>
		</nav>
	</div>
);

export default Navbar;
